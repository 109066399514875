<template>
   <v-expand-transition>
      <v-form v-show="typeof option.id === 'string' || option.id >= 0" ref="form">
         <v-card-text class="pt-9">
            <v-row>
               <v-col cols="12" md="3">
                  <v-text-field v-model="form.name" label="Integration Name" placeholder="Nama Integrasi" outlined hide-details required :rules="[v => !!v]" />
               </v-col>
               <v-col cols="12" md="3">
                  <v-select v-model="form.module" :items="[
                        // { text: 'Access Control System', value: 'NAC-50-WEB' },
                        { text: 'Access Controller Module', value: 'NAC-50-IOT' },
                        { text: 'Qrcode Interface Module', value: 'NAC-50-QRR' },
                        { text: 'Reader Interface Module', value: 'NAC-50-RIM' },
                     ]" label="Hardware Module" placeholder="Modul Hardware" outlined hide-details required :rules="[v => !!v]"
                     :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                     :disabled="form.id !== 0"
                     />
               </v-col>
               <v-col cols="12" md="3">
                  <v-text-field v-model="form.ipV4" label="IPv4" placeholder="IP Address" outlined hide-details required :rules="[v => !!v]" />
               </v-col>
               <template v-if="form.module === 'NAC-50-WEB'">
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.username" label="Username" placeholder="Nama Pengguna" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.password" label="Password" placeholder="Kata Rahasia" outlined hide-details
                        :type="(form.id===0&&seen)?'text':'password'" :append-icon="form.id===0?seen?'mdi-eye':'mdi-eye-off':''"
                        @click:append="seen=!seen"
                     />
                  </v-col>
               </template>
               <v-col v-if="form.module === 'NAC-50-RIM'" cols="12" md="3">
                  <v-select v-model="form.mode" :items="[
                        { text: 'Gate Access', value: 'Gate' },
                        { text: 'Lift Access', value: 'Lift' },
                        { text: 'Door Access', value: 'Door', disabled: true },
                        { text: 'Rack Access', value: 'Rack', disabled: true },
                        { text: 'Lock Access', value: 'Lock', disabled: true },
                     ]" label="Access Mode" placeholder="Mode Akses" outlined hide-details required :rules="[v => !!v]"
                     />
               </v-col>
               <!-- <template v-if="form.module === 'NAC-50-QRR'">
                  <v-col cols="12" md="3">
                     <v-autocomplete v-model="form.output" :items="outputs" label="Hardware Output" placeholder="Modul Keluaran" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="identity"
                        :return-object="false"
                        />
                  </v-col>
               </template> -->
            </v-row>
            <v-row v-if="form.module === 'NAC-50-QRR'">
               <v-col cols="12" md="3" offset-md="3">
                  <v-autocomplete v-model="form.output" :items="outputs" label="Hardware Output" placeholder="Modul Keluaran" outlined hide-details required :rules="[v => !!v]"
                     item-text="name" item-value="identity"
                     :return-object="false"
                     />
               </v-col>
               <v-col cols="12" md="3">
                  <v-select v-model="form.channel" :items="[
                        { text: 'Channel 1', value: '1' },
                        { text: 'Channel 2', value: '2' },
                     ]" label="Output Channel" placeholder="Kanal Keluaran" outlined hide-details required :rules="[v => !!v]"
                     :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                     />
               </v-col>
            </v-row>
         </v-card-text>
         <v-divider />
         <v-card-text class="pb-6">
            <v-row class="mt-0">
               <v-col cols="12" class="py-0 d-flex">
                  <v-btn v-if="$hasRole(option.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateOption'" @click="updateRecord">
                     Update
                  </v-btn>
                  <v-btn x-large color="accent" depressed outlined class="mr-4" @click="option.id = -1">
                     {{ $store.state.process === 'updateOption' ? 'Close' : 'Cancel' }}
                  </v-btn>
                  <v-spacer />
               </v-col>
            </v-row>
         </v-card-text>
         <v-divider />
      </v-form>
   </v-expand-transition>
</template>

<script>
const empty = { kind: 'Integration', name: '', module: '', ipV4: '', channel: '', output: '', mode: '', username: '', password: '' }
export default {
   name: 'NIntegrationForm',

   props: {
      option: { type: Object },
      outputs: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, empty),
      seen: false,
   }),

   computed: {
   },
   watch: {
      'option.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, empty, typeof id === 'string' ? this.option : { id: 0 })
      },
   },
   methods: {
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', this.form)
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
/*
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
*/
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
