var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(typeof _vm.option.id === 'string' || _vm.option.id >= 0),expression:"typeof option.id === 'string' || option.id >= 0"}],ref:"form"},[_c('v-card-text',{staticClass:"pt-9"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Integration Name","placeholder":"Nama Integrasi","outlined":"","hide-details":"","required":"","rules":[function (v) { return !!v; }]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
                     // { text: 'Access Control System', value: 'NAC-50-WEB' },
                     { text: 'Access Controller Module', value: 'NAC-50-IOT' },
                     { text: 'Qrcode Interface Module', value: 'NAC-50-QRR' },
                     { text: 'Reader Interface Module', value: 'NAC-50-RIM' } ],"label":"Hardware Module","placeholder":"Modul Hardware","outlined":"","hide-details":"","required":"","rules":[function (v) { return !!v; }],"return-object":false,"menu-props":{ bottom: true, offsetY: true },"disabled":_vm.form.id !== 0},model:{value:(_vm.form.module),callback:function ($$v) {_vm.$set(_vm.form, "module", $$v)},expression:"form.module"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"IPv4","placeholder":"IP Address","outlined":"","hide-details":"","required":"","rules":[function (v) { return !!v; }]},model:{value:(_vm.form.ipV4),callback:function ($$v) {_vm.$set(_vm.form, "ipV4", $$v)},expression:"form.ipV4"}})],1),(_vm.form.module === 'NAC-50-WEB')?[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Username","placeholder":"Nama Pengguna","outlined":"","hide-details":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Password","placeholder":"Kata Rahasia","outlined":"","hide-details":"","type":(_vm.form.id===0&&_vm.seen)?'text':'password',"append-icon":_vm.form.id===0?_vm.seen?'mdi-eye':'mdi-eye-off':''},on:{"click:append":function($event){_vm.seen=!_vm.seen}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]:_vm._e(),(_vm.form.module === 'NAC-50-RIM')?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
                     { text: 'Gate Access', value: 'Gate' },
                     { text: 'Lift Access', value: 'Lift' },
                     { text: 'Door Access', value: 'Door', disabled: true },
                     { text: 'Rack Access', value: 'Rack', disabled: true },
                     { text: 'Lock Access', value: 'Lock', disabled: true } ],"label":"Access Mode","placeholder":"Mode Akses","outlined":"","hide-details":"","required":"","rules":[function (v) { return !!v; }]},model:{value:(_vm.form.mode),callback:function ($$v) {_vm.$set(_vm.form, "mode", $$v)},expression:"form.mode"}})],1):_vm._e()],2),(_vm.form.module === 'NAC-50-QRR')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","offset-md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.outputs,"label":"Hardware Output","placeholder":"Modul Keluaran","outlined":"","hide-details":"","required":"","rules":[function (v) { return !!v; }],"item-text":"name","item-value":"identity","return-object":false},model:{value:(_vm.form.output),callback:function ($$v) {_vm.$set(_vm.form, "output", $$v)},expression:"form.output"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
                     { text: 'Channel 1', value: '1' },
                     { text: 'Channel 2', value: '2' } ],"label":"Output Channel","placeholder":"Kanal Keluaran","outlined":"","hide-details":"","required":"","rules":[function (v) { return !!v; }],"return-object":false,"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.form.channel),callback:function ($$v) {_vm.$set(_vm.form, "channel", $$v)},expression:"form.channel"}})],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"pb-6"},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0 d-flex",attrs:{"cols":"12"}},[(_vm.$hasRole(_vm.option.kind.toLowerCase(), 'CU'))?_c('v-btn',{staticClass:"mr-4",attrs:{"x-large":"","color":"accent","depressed":"","loading":_vm.$store.state.process === 'updateOption'},on:{"click":_vm.updateRecord}},[_vm._v(" Update ")]):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"x-large":"","color":"accent","depressed":"","outlined":""},on:{"click":function($event){_vm.option.id = -1}}},[_vm._v(" "+_vm._s(_vm.$store.state.process === 'updateOption' ? 'Close' : 'Cancel')+" ")]),_c('v-spacer')],1)],1)],1),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }